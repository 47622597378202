/**
 * SalesSolutions Backend
 * Collection of APIs for SalesSolutions
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SalesOptionCartItem } from './salesOptionCartItem';
import { WarrantyExtensionItem } from './warrantyExtensionItem';
import { CustomArticleItem } from './customArticleItem';
import { ContractItem } from './contractItem';
import { MccItem } from './mccItem';
import { Currency } from './currency';
import { SolutionItem } from './solutionItem';
import { TuningCenterItem } from './tuningCenterItem';
import { ProductItem } from './productItem';


/**
 * This class holds all abstract cart related data.
 */
export interface CartOverview { 
    /**
     * Id of the cart
     */
    
    id: number;
    /**
     * Model name of the chosen product in the cart
     */
    
    model?: string | null;
    /**
     * Provide information, if cart is readOnly, or not.
     */
    
    locked: boolean;
    /**
     * Provide Image of the mandatory CartItem
     */
    
    cart_image?: string | null;
    /**
     * List of all items with an origin from a configurable item.
     */
    
    product_items?: Array<ProductItem> | null;
    /**
     * List of all items with an origin from a contract.
     */
    
    contract_items?: Array<ContractItem> | null;
    /**
     * List of all items with an origin from a custom article.
     */
    
    custom_article_items?: Array<CustomArticleItem> | null;
    /**
     * List of all items with an origin from a sales option.
     */
    
    sales_option_items?: Array<SalesOptionCartItem> | null;
    /**
     * List of all items with an origin from a solution.
     */
    
    solution_items?: Array<SolutionItem> | null;
    /**
     * List of all items with an origin from a mcc.
     */
    
    mcc_items?: Array<MccItem> | null;
    /**
     * List of all items with an origin from a tuning center.
     */
    
    tuning_center_items?: Array<TuningCenterItem> | null;
    /**
     * List of al items with an origin from a warranty extension.
     */
    
    warranty_extension_items?: Array<WarrantyExtensionItem> | null;
    /**
     * Date of cart creation
     */
    
    created_on: Date;
    /**
     * Date of cart modification
     */
    
    modified_on: Date;
    /**
     * Provide information of the version within the project
     */
    
    version: number;
    /**
     * Shows, if the selected cart is the mandatory one in the project
     */
    
    active: boolean;
    /**
     * Shows, if the selected cart has a valid configuration, or not
     */
    
    valid: boolean;
    /**
     * State of the cart
     */
    
    state: CartOverviewState;
    /**
     * Calculated price to end customer in local currency.
     */
    
    final_price?: number | null;
    /**
     * Calculated summarized priced based on cart items
     */
    
    sales_price: number;
    /**
     * Offer creation date
     */
    
    offer_created_on?: Date | null;
    /**
     * Id of the associated project
     */
    
    project_id: number;
    /**
     * The origin cart id from the source after a clone.
     */
    
    original_cart_id?: number | null;
    /**
     * Date of the price list
     */
    
    price_list_date: Date;
    /**
     * Division of the Product              <example>B1</example><example>A1</example><example>D1</example><example>H1</example><example>F1</example>
     */
    
    product_line: string;
    /**
     * ISO 639-1 language code
     */
    
    language: string;
    /**
     * Provided comment on cart level
     */
    
    comment?: string | null;
    
    currency_settings: Currency;
    /**
     * Number of days the cart is valid.
     */
    
    valid_until: Date;
}
export enum CartOverviewState {
    Created = 'Created',
    Active = 'Active',
    Expired = 'Expired',
    Closed = 'Closed',
    Ordered = 'Ordered',
    Done = 'Done'
};



