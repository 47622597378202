import { Component } from '@angular/core';
import { environment } from '../../environments/environment';

@Component({
  selector: 'tks-onboarding',
  templateUrl: './onboarding.component.html',
  standalone: false,
})
export class OnboardingComponent {
  isGeneralOnboardingScreenEnabled: boolean;
  selectedLanguage: { code: string; display_name: string } = {
    code: 'EN',
    display_name: 'English',
  };

  constructor() {
    this.isGeneralOnboardingScreenEnabled =
      environment.FEATUREFLAGS.enable_general_onboarding_screen === 'true';
  }

  onLanguageSelectionChange(selectedLanguage: string) {
    this.selectedLanguage.code = selectedLanguage;
  }
}
