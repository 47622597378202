import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Guide, Section, UserGuide } from '@sales-libs/sc-summary/data-access';
import {
  SlScSummaryComponent,
  SlScSummarySelectors,
} from '@sales-libs/sc-summary/feature';
import { SectionType } from '@sales-libs/shared/data-access/sc-generated';
import { ScSharedActions } from '@sales-libs/shared/feature';
import { formatUrlFriendlyName } from '@sales-libs/shared/util';
import { filterTruthy } from '@shared-lib/rxjs';
import { take } from 'rxjs';
import { environment } from '../../environments/environment';

export const imports = [CommonModule, SlScSummaryComponent];

@Component({
  selector: 'tks-summary',
  imports: imports,
  templateUrl: './summary.component.html',
})
export class SummaryComponent implements OnInit {
  userGuid: string;
  guide: Guide;
  userGuide: UserGuide;
  productLineName: string;
  selectedLanguage: string;
  scUrl = environment.LINKS.TKS;
  sectionType = SectionType.Configuration;
  summarySection: Section | undefined;
  summarySectionName: string;
  stepName: string;
  englishLanguageCode = 'en';
  urlFriendlyProductLineEnum: { [key: string]: string } = {};

  readonly SectionType = SectionType;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly store: Store,
    public actions: Actions,
  ) {}

  ngOnInit(): void {
    this.getUrlParameters();
    this.getSharedUserGuide();
    this.getProductLineName();
  }

  getUrlParameters(): void {
    this.route.params.pipe(take(1)).subscribe((event) => {
      this.productLineName = event.productLine;
      this.userGuid = event.userGuid;
      this.selectedLanguage = event.language;

      this.updateSharedStore(
        this.productLineName,
        this.userGuid,
        event.stepName ? this.summarySection : undefined,
        this.selectedLanguage,
      );
    });
  }

  getSummarySection() {
    const configurationSections = this.guide?.sections?.filter(
      (section) => section.type === this.sectionType,
    );

    if (configurationSections) {
      this.summarySection =
        configurationSections[configurationSections?.length - 1] ?? '';
      this.summarySectionName =
        configurationSections[
          configurationSections?.length - 1
        ]?.name?.toLowerCase() ?? '';
    }
  }

  updateSharedStore(
    productLineName: string,
    userGuid: string,
    currentSection: Section | undefined,
    selectedLanguageCode: string,
  ) {
    this.store.dispatch(
      ScSharedActions.SetScSharedState({
        productLineName,
        userGuid,
        currentSection,
        selectedLanguageCode,
      }),
    );
  }

  getSharedUserGuide(): void {
    this.store
      .pipe(select(SlScSummarySelectors.userGuide), filterTruthy(), take(1))
      .subscribe((userGuide) => {
        this.userGuide = userGuide;
        this.selectedLanguage =
          this.userGuide.language_code?.toLowerCase() ??
          this.englishLanguageCode;
      });
  }

  getProductLineName(): void {
    if (this.router.url.includes('/share/')) {
      this.store
        .pipe(
          select(SlScSummarySelectors.userConfiguration),
          filterTruthy(),
          take(1),
        )
        .subscribe((userConfiguration) => {
          const guideUrlName = Object.keys(userConfiguration.product_line)[0];
          this.productLineName = formatUrlFriendlyName(guideUrlName) ?? '';
        });
    }
  }

  navigateToConfigurationStep(sectionName: string): void {
    const guid = this.userGuid || this.userGuide.user_guid;
    const sectionType = formatUrlFriendlyName(SectionType.Configuration);
    formatUrlFriendlyName(sectionName);

    this.router.navigateByUrl(
      `/${this.selectedLanguage}/${this.productLineName}/${guid}/${sectionType}/${sectionName}`,
    );

    const configurationSection = this.guide?.sections?.find(
      (section) => formatUrlFriendlyName(section.name) === sectionName,
    );

    this.updateSharedStore(
      this.productLineName,
      this.userGuid,
      configurationSection,
      this.selectedLanguage,
    );
  }

  handleStartNewConfiguration() {
    this.router.navigate(['/']);
  }
}
