import { WarrantyExtensionItem } from '../generated';

export const testWarrantyExtensionItem: WarrantyExtensionItem = {
  material: 'test material',
  original_sales_price: 79.99,
  quantity: 1,
  is_discountable: true,
  is_edited: false,
  company_bpm_id: 54321,
  cart_item_price_comparer_id: '3',
  sort_key: 2,
  id: 'testWarrantyExtensionItemId',
  cart_id: 98765,
  name: 'testWarrantyExtensionItemName',
  short_description: 'Test Warranty',
  long_description: 'This is a test warranty extension item.',
  sales_price: 69.99,
  purchase_price: 59.99,
  is_optional: false,
  cart_item_group_id: 1,
  sort_key_optional: 0,
  percentage_value: 3,
  sap_percentage_value: 1,
};
