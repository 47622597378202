import { Component } from '@angular/core';
import { CookieConsentService } from '@paldesk/cookie-consent';
import { GoogleAnalytics4Service } from '@shared-lib/google-analytics';

@Component({
  selector: 'tks-root',
  templateUrl: './app.component.html',
  standalone: false,
})
export class AppComponent {
  constructor(
    private _ga4service: GoogleAnalytics4Service,
    private cookieService: CookieConsentService,
  ) {
    this.cookieService.cookieConsent$.subscribe((cookieConsent) => {
      if (cookieConsent.performanceCookies) {
        this._ga4service.upsertGA4();
      } else {
        this._ga4service.removeGA4();
      }
    });
  }
}
