import { createAction, props } from '@ngrx/store';
import {
  BestInvestProjectParameters,
  ProblemDetails,
} from '@sales-libs/upselling/data-access';

export namespace SharedContractActions {
  export const createProjectParameters = createAction(
    '[Shared_Contract] CREATE_PROJECT_PARAMETERS',
    props<{ payload: number }>(),
  );
  export const createProjectParametersSuccess = createAction(
    '[Shared_Contract] CREATE_PROJECT_PARAMETERS_SUCCESS',
    props<{ payload: BestInvestProjectParameters }>(),
  );
  export const createProjectParametersError = createAction(
    '[Shared_Contract] CREATE_PROJECT_PARAMETERS_ERROR',
    props<{ payload: ProblemDetails }>(),
  );
}
