/* eslint-disable sonarjs/no-duplicate-string */
import {
  animate,
  keyframes,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ds-card-carousel',
  templateUrl: './card-carousel.component.html',
  styleUrls: ['./card-carousel.component.scss'],
  animations: [
    trigger('fadeInAnimation', [
      transition(':enter', [
        animate(
          '1000ms',
          keyframes([
            style({ opacity: 0, offset: 0 }),
            style({ opacity: 1, offset: 1 }),
          ]),
        ),
      ]),
    ]),
  ],
  imports: [
    FlexModule,
    MatButtonModule,
    MatIconModule,
    DsSpacingModule,
    CommonModule,
  ],
})
export class DsCardCarouselComponent implements AfterViewInit {
  flexValue = '1 0 50%';
  slides = 0;
  @Input() height = '80vh';
  offset: string;
  currentSlide = 0;
  subscription: Subscription;
  slidesNumber = [0];
  numberOfCards = 4;
  @Input()
  isSingleCard = false;
  @ViewChild('carousel') carousel: ElementRef;
  cards: NodeListOf<HTMLElement>;
  @ViewChild('fadeAnimation') animation: ElementRef;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.currentSlide = 0;
    this.setNumberOfSlides();
    this.checkSlidesNumber();
  }

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
  ) {}
  previousSlide() {
    this.renderer.setStyle(
      this.elementRef.nativeElement.querySelector('#fadeAnimation'),
      'transition',
      'transform 0.3s ease',
    );

    if (this.currentSlide >= this.numberOfCards) {
      this.currentSlide = this.currentSlide - this.numberOfCards;

      this.offset = `translate3d(-${
        (this.currentSlide / this.numberOfCards) * 100
      }%,0,0)`;
    } else {
      if (this.numberOfCards > 1) {
        const modulo = this.slides % this.numberOfCards;
        this.currentSlide =
          this.slides - this.numberOfCards + (this.numberOfCards - modulo);
      } else {
        this.currentSlide = this.slides - this.numberOfCards;
      }
      this.offset = `translate3d(-${
        (this.currentSlide / this.numberOfCards) * 100
      }%,0,0)`;
    }
  }

  nextSlide() {
    this.renderer.setStyle(
      this.elementRef.nativeElement.querySelector('#fadeAnimation'),
      'transition',
      'transform 0.3s ease',
    );
    if (this.slides - this.currentSlide > this.numberOfCards) {
      this.currentSlide = this.currentSlide + this.numberOfCards;

      this.offset = `translate3d(${
        (-this.currentSlide / this.numberOfCards) * 100
      }%,0,0)`;
      this.currentSlide =
        this.currentSlide === this.slides ? 0 : this.currentSlide;
    } else {
      this.currentSlide = 0;
      this.offset = `translate3d(0,0,0)`;
    }
  }

  slideTo(slideNumber: number) {
    this.renderer.setStyle(
      this.elementRef.nativeElement.querySelector('#fadeAnimation'),
      'transition',
      'transform 0.3s ease',
    );

    if (this.slides - slideNumber >= this.numberOfCards) {
      this.currentSlide = this.numberOfCards * slideNumber;

      this.offset = `translate3d(${
        (-this.currentSlide / this.numberOfCards) * 100
      }%,0,0)`;
      this.currentSlide =
        this.currentSlide === this.slides ? 0 : this.currentSlide;
    } else {
      this.currentSlide = 0;
      this.offset = `translate3d(0,0,0)`;
    }
  }

  setNumberOfSlides() {
    const width = this.carousel.nativeElement.offsetWidth;
    if (this.isSingleCard) {
      this.numberOfCards = 1;
      return;
    }
    if (width >= 1050) {
      this.numberOfCards = 4;
    } else if (width < 1050 && width >= 910) {
      this.numberOfCards = 3;
    } else if (width < 910 && width >= 670) {
      this.numberOfCards = 2;
    } else {
      this.numberOfCards = 1;
    }
  }

  ngAfterViewInit() {
    const slides: HTMLElement[] = Array.from(
      this.elementRef.nativeElement.querySelectorAll(
        '#fadeAnimation > mat-card',
      ),
    );
    slides.forEach((slide: HTMLElement) => {
      slide.classList.add('slide');
    });
    if (!this.numberOfCards) {
      this.numberOfCards = 4;
    }
    this.cards = this.elementRef.nativeElement.querySelectorAll('.slide');
    this.slides = this.cards.length;
    this.setNumberOfSlides();
    this.checkSlidesNumber();
    this.flexValue = `1 0 ${100 / this.numberOfCards}%`;
    this.elementRef.nativeElement
      .querySelectorAll('mat-card')
      .forEach((item) => {
        item.style.margin = '10px';
      });
  }

  checkSlidesNumber() {
    this.cards.forEach((card) => {
      card.classList.forEach((className) => {
        const regex = /flex-(\d+)$/;
        const isMatch = className.match(regex);
        if (isMatch) {
          card.classList.remove(className);
        }
      });

      card.classList.add(`flex-${Math.round(100 / this.numberOfCards)}`);
    });
    setTimeout(() => {
      this.slidesNumber = Array.from(
        Array(Math.ceil(this.cards.length / this.numberOfCards)).keys(),
      );
    });
  }
}
