import { Component } from '@angular/core';

@Component({
  selector: 'tks-general-onboarding',
  templateUrl: './general-onboarding.component.html',
  styleUrls: ['./general-onboarding.component.scss'],
  standalone: false,
})
export class GeneralOnboardingComponent {
  selectedLanguage: { code: string; display_name: string } = {
    code: 'EN',
    display_name: 'English',
  };

  onLanguageSelectionChange(selectedLanguage: string) {
    this.selectedLanguage.code = selectedLanguage;
  }
}
