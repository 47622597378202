import { CommonModule, NgOptimizedImage } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { dsConfig } from '@design-system/cdk/config';
import { DsEquipmentIconModule } from '@design-system/component/equipment-icon';
import { SlSharedScAdditionalInfoDialogComponent } from '../additional-info-dialog/additional-info-dialog.component';

export const imports = [DsEquipmentIconModule, CommonModule, NgOptimizedImage];

@Component({
  selector: 'sl-shared-sc-product-line-icons',
  imports: imports,
  templateUrl: './product-line-icons.component.html',
  styleUrls: ['./product-line-icons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SlSharedScProductLineIconsComponent {
  @Input() productLineName: string;
  @Input() selectedModelName: string | null | undefined;
  @Input() urlFriendlyProductLineEnum: { [key: string]: string } = {};
  @Input() environmentLink: string;
  @Output() startNewConfiguration = new EventEmitter<void>();

  constructor(public dialog: MatDialog) {}

  openAdditionalInfoDialog(): void {
    const dialogRef = this.dialog.open(
      SlSharedScAdditionalInfoDialogComponent,
      {
        width: String(dsConfig.spacing * 43) + 'px',
        data: {
          productLineName: this.productLineName,
          selectedModelName: this.selectedModelName,
          urlFriendlyProductLineEnum: this.urlFriendlyProductLineEnum,
          environmentLink: this.environmentLink,
        },
      },
    );

    dialogRef.componentInstance.startNewConfiguration.subscribe(() => {
      this.handleStartNewConfiguration();
    });
  }

  handleStartNewConfiguration(): void {
    this.startNewConfiguration.emit();
  }
}
