import { HttpStatusCode } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import {
  ConfigurationCode,
  Currencies,
  Form,
  SummaryService,
  UserGuide,
} from '@sales-libs/sc-summary/data-access';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { SlScSummaryActions } from './summary.actions';

@Injectable()
export class SlScSummaryEffects {
  private readonly actions$ = inject(Actions);

  getUserConfiguration$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SlScSummaryActions.getUserConfiguration),
      switchMap((payload) =>
        this.summaryService
          .getUserConfiguration(
            payload.guideId,
            payload.guid,
            payload.language,
            payload.currencyCode,
          )
          .pipe(
            map((userConfigurationPayload: any) =>
              SlScSummaryActions.getUserConfigurationSuccess({
                userConfiguration: userConfigurationPayload,
              }),
            ),
            catchError((err) => {
              if (err.status === HttpStatusCode.NotFound) {
                this._router.navigate(['special']);
              }
              return of(
                SlScSummaryActions.getUserConfigurationError({
                  payload: err.error,
                }),
              );
            }),
          ),
      ),
    ),
  );

  getConfigurationCode$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SlScSummaryActions.getConfigurationCode),
      switchMap((payload) =>
        this.summaryService
          .createCodeForUserGuide(
            payload.guideId,
            payload.guid,
            payload.language,
            payload.currencyCode,
          )
          .pipe(
            map((configurationCodePayload: ConfigurationCode) =>
              SlScSummaryActions.getConfigurationCodeSuccess({
                configurationCode: configurationCodePayload,
              }),
            ),
            catchError((err) => {
              if (err.status === HttpStatusCode.NotFound) {
                this._router.navigate(['special']);
              }
              return of(
                SlScSummaryActions.getConfigurationCodeError({
                  payload: err.error,
                }),
              );
            }),
          ),
      ),
    ),
  );

  getSharedUserGuide$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SlScSummaryActions.getSharedUserGuide),
      switchMap((payload) =>
        this.summaryService
          .createUserGuideFromCode(payload.configurationCode)
          .pipe(
            map((sharedUserGuidePayload: UserGuide) =>
              SlScSummaryActions.getSharedUserGuideSuccess({
                userGuide: sharedUserGuidePayload,
              }),
            ),
            catchError((err) => {
              if (err.status === HttpStatusCode.NotFound) {
                this._router.navigate(['special']);
              }
              return of(
                SlScSummaryActions.getSharedUserGuideError({
                  payload: err.error,
                }),
              );
            }),
          ),
      ),
    ),
  );

  getContactFormDetails$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SlScSummaryActions.getContactFormDetails),
      switchMap((payload) =>
        this.summaryService.getContactForm(payload.language).pipe(
          map((contactFormPayload: Form) =>
            SlScSummaryActions.getContactFormDetailsSuccess({
              form: contactFormPayload,
            }),
          ),
          catchError((err) => {
            if (err.status === HttpStatusCode.NotFound) {
              this._router.navigate(['special']);
            }
            return of(
              SlScSummaryActions.getContactFormDetailsError({
                payload: err.error,
              }),
            );
          }),
        ),
      ),
    ),
  );

  submitContactForm$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SlScSummaryActions.submitContactForm),
      switchMap((payload) =>
        this.summaryService
          .postContactForm(
            payload.guideId,
            payload.guid,
            payload.language,
            payload.contactForm,
          )
          .pipe(
            map(() => SlScSummaryActions.submitContactFormSuccess()),
            catchError((err) => {
              if (err.status === HttpStatusCode.NotFound) {
                this._router.navigate(['special']);
              }
              return of(
                SlScSummaryActions.submitContactFormError({
                  payload: err.error,
                }),
              );
            }),
          ),
      ),
    ),
  );

  getCurrencies$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SlScSummaryActions.getCurrencies),
      switchMap((payload) =>
        this.summaryService.getCurrencies(payload.guideId).pipe(
          map((currenciesPayload: Currencies) =>
            SlScSummaryActions.getCurrenciesSuccess({
              currencies: currenciesPayload,
            }),
          ),
          catchError((err) =>
            of(
              SlScSummaryActions.getCurrenciesError({
                payload: err.error,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  constructor(
    private summaryService: SummaryService,
    private readonly _router: Router,
  ) {}
}
