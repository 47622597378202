import { SolutionItem } from '../generated/model/solutionItem';
import { testSolutionOption } from './solutionOption';

export const testSolutionItem: SolutionItem = {
  quantity: 1,
  original_quantity: null,
  company_bpm_id: 123,
  solution_id: '20',
  name: 'Test Item',
  picture_name: null,
  picture_type: null,
  id: null,
  cart_id: 456,
  original_name: null,
  short_description: 'Short description',
  original_short_description: null,
  long_description: 'Long description',
  original_long_description: null,
  is_ppi_relevant: true,
  original_is_ppi_relevant: null,
  sales_price: 100.0,
  original_sales_price: null,
  purchase_price: 80.0,
  original_purchase_price: null,
  is_discountable: true,
  original_is_discountable: null,
  cart_item_group_id: null,
  is_edited: false,
  is_optional: false,
  sort_key: 1,
  sort_key_optional: 2,
  cart_item_price_comparer_id: null,
  epp: null,
  encrypted_original_purchase_price: null,
  options: [testSolutionOption],
};
