import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SlScMapPalfingerLangPipe } from '@sales-libs/shared/util';

export const imports = [
  CommonModule,
  TranslateModule,
  SlScMapPalfingerLangPipe,
  FlexLayoutModule,
];

@Component({
  selector: 'sl-shared-sc-footer',
  imports: imports,
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class SlSharedScFooterComponent implements OnChanges {
  @Input() language: string;
  readonly palfingerAppBasePath = 'https://www.palfinger.com/';
  privacy_policy: string;
  code_of_conduct: string;
  integrity_line: string;
  terms_and_conditions: string;

  copyrightYear = new Date().getFullYear();

  constructor(private translateService: TranslateService) {}

  ngOnChanges(): void {
    this.translateService.use(this.language.toLowerCase());
    this.setLinkTranslations();
  }

  setLinkTranslations(): void {
    this.translateService
      .get('turnkey_solution.footer.privacy_policy_link')
      .subscribe((translated: string) => {
        this.privacy_policy = '/' + translated;
      });

    this.translateService
      .get('turnkey_solution.footer.code_of_conduct_link')
      .subscribe((translated: string) => {
        this.code_of_conduct = '/' + translated;
      });

    this.translateService
      .get('turnkey_solution.footer.integrity_line_link')
      .subscribe((translated: string) => {
        this.integrity_line = '/' + translated;
      });

    this.translateService
      .get('turnkey_solution.footer.terms_and_conditions_link')
      .subscribe((translated: string) => {
        this.terms_and_conditions = '/' + translated;
      });
  }
}
