import { transition, trigger, useAnimation } from '@angular/animations';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { fadeIn, fadeOut } from './carousel.animations';

import { AnimationType } from './model/animation-type.model';

export interface Slide {
  headline?: string;
  src: string;
  description?: string;
}

@Component({
  selector: 'ds-image-carousel',
  templateUrl: './image-carousel.component.html',
  styleUrls: ['./image-carousel.component.scss'],
  animations: [
    trigger('slideAnimation', [
      transition('void => fade', [
        useAnimation(fadeIn, { params: { time: '500ms' } }),
      ]),
      transition('fade => void', [
        useAnimation(fadeOut, { params: { time: '500ms' } }),
      ]),
    ]),
  ],
  standalone: false,
})
export class DsImageCarouselComponent implements OnDestroy, OnInit {
  @Input() slides: Slide[] = [];
  @Input() animationType = AnimationType.Fade;
  @Input() autoSlide = 0;
  @Input() height = '80vh';
  @ViewChild('view') carousel;
  timeOutID;

  currentSlide = 0;
  private destroy$ = new Subject<void>();
  subscription: Subscription;

  previousSlide() {
    this.currentSlide--;
    if (this.currentSlide < 0) {
      this.currentSlide = this.slides.length - 1;
    }
  }

  nextSlide() {
    this.currentSlide++;
    this.currentSlide =
      this.currentSlide === this.slides.length ? 0 : this.currentSlide;
  }

  preloadImages() {
    for (const slide of this.slides) {
      new Image().src = slide.src;
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    if (this.timeOutID) {
      clearInterval(this.timeOutID);
    }
  }

  ngOnInit() {
    this.preloadImages();
    if (this.autoSlide) {
      this.timeOutID = setInterval(() => {
        this.nextSlide();
      }, this.autoSlide);
    }
  }

  slideCarousel(evt) {
    const side =
      Math.abs(evt.deltaX) > 40 ? (evt.deltaX > 0 ? 'right' : 'left') : '';
    if (side === 'right') {
      this.previousSlide();
    } else if (side === 'left') {
      this.nextSlide();
    }
  }

  slideToImage(index) {
    this.currentSlide = index;
  }
}
