import { SolutionOption } from '../generated/model/solutionOption';

export const testSolutionOption: SolutionOption = {
  /**
   * The code of the solution sales option.
   */

  code: 'test code',
  /**
   * The name of the solution sales option.
   */

  name: 'test name',
  /**
   * The price of the solution sales option.
   */

  price: 10,
  /**
   * The id of the solution item.
   */

  solution_item_id: 'test solution item id',
};
